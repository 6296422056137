// https://dzone.com/articles/how-to-use-graphql-in-react-using-hooks
const reducer = (state, action) => {
  switch(action.type) {
    case 'PENDING':
      return { bookings: [], loading: true };
    case 'LOADED':
      return { bookings: action.memberBookings, loading: false };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: state.error,
      };
    case 'EDIT': {
      // replace the element with the one in the state
      const newBookings = state.bookings.map((item) => {
        return (item.id === action.updatedBooking.id) ? action.updatedBooking : item;
      });
      return {
        ...state,
        bookings: newBookings,
      };
    }
    default:
      console.log('hit default');
      return { ...state };
  }
};
//{type: "ADD", {user: {attributes}}};
//{type: "REMOVE", {id: userId}};
//{type: "FINANCIAL", {id: userId}};
//{type: "UNFINANCIAL", {id: userId}}; // not admin
//{type: "TRANSFER_SHARE", {id: shareID, recipient: {userId}};

export default reducer;
