import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import useForm from '../../helpers/useForm';

function OccupantForm(props) {
  const { booking, isEditing, updateBooking } = props;
  const { occupant_name, occupant_email, occupant_phone } = booking;
  // Define your state schema
  const stateSchema = {
    name: { value: occupant_name, error: '', blur: false },
    email: { value: occupant_email, error: '', blur: false },
    phone: { value: occupant_phone, error: '', blur: false },
  };

  // Define your validationStateSchema
  // Note: validationStateSchema and stateSchema property
  // should be the same in-order validation works!
  const validationStateSchema = {
    email: {
      required: true,
      validator: {
        regEx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        error: 'Invalid email format.',
      },
    },
    phone: {
      required: true,
    },
    name: {
      required: true,
    },
  };

  const handleSubmit = (async (state) => {
    const { name, email, phone } = state;
    const attr = {
      occupant_name: name.value,
      occupant_email: email.value,
      occupant_phone: phone.value,
    };
    updateBooking(attr);
    isEditing(false);
  });

  const {
    state, handleOnChange, handleOnBlur, handleOnSubmit, disable,
  } = useForm(
    stateSchema,
    validationStateSchema,
    handleSubmit,
  );
  return (
    <div>
      <Form onSubmit={handleOnSubmit}>
        <Form.Group widths="equal">
          <Form.Input
            label="Name of Occupant(s)"
            type="text"
            name="name"
            placeholder="Occupant Name(s)"
            error={(state.name.blur && state.name.error.length !== 0) && state.owner_names.error}
            value={state.name.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          <Form.Input
            label="Occupant Contact Phone Number(s)"
            type="text"
            name="phone"
            placeholder="Phone number(s)"
            error={(state.phone.blur && state.phone.error.length !== 0) && state.phone.error}
            value={state.phone.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          <Form.Input
            label="Occupant Contact Email"
            type="email"
            name="email"
            placeholder="Email"
            error={(state.email.blur && state.email.error.length !== 0) && state.email.error}
            value={state.email.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
        </Form.Group>
        <Button 
          type="submit"
          disabled={disable}
        >
          Submit
        </Button>
        <Button type="submit" onClick={() => isEditing(false)}>
          Cancel
        </Button>
      </Form>
    </div>
  );
}
export default OccupantForm;
