import React from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';

// AppSync and Apollo libraries
import { ApolloProvider } from 'react-apollo';
import Amplify, { Hub } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react/dist/Auth';
import Analytics from 'react-segment';
import { AuthProvider } from './context/AuthContext';

// Amplify
// Removing SignUp option by customizing withAuthenticator - https://aws-amplify.github.io/docs/js/authentication

// Components
import ResponsiveContainer from './Components/ResponsiveContainer';
import Navigation from './Components/Navigation';
import config from './config';

// AWS AppSyncClient is broken when it comes to Hooks.
// Had to adopt workaround documented here
// https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/450
// nc9 / ph0ph0 solution worked for me.
import client from './AwsApolloClient';

// https://aws-amplify.github.io/docs/js/authentication#manual-setup
Amplify.configure({
  Auth: {

    // REQUIRED - Amazon Cognito Region
    region: config.region,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: config.cognito.client_id,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.cognito.user_pool_id,

    identityPoolId: config.cognito.identity_pool_id,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
  },
});

Analytics.default.load(config.segment);

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <ResponsiveContainer>
          <Navigation />
        </ResponsiveContainer>
      </AuthProvider>
    </div>
  );
}

Hub.listen('auth', (data) => {
  switch (data.payload.event) {
    case 'signIn':
      // TODO: the react-segment library should proxy but doesn't - fix this
      //   but use the window in the meantime to get analytics flowing
      window.analytics.identify(data.payload.data.attributes.sub, {
        email: data.payload.data.attributes.email,
        name: data.payload.data.attributes['custom:owner_names'],
      });
      window.analytics.page(window.location.pathname);
      break;
    default:
      break;
  }
});
const AppWithAuth = withAuthenticator(App, { includeGreetings: false });

export default () => (
  <ApolloProvider client={client}>
    <AppWithAuth />
  </ApolloProvider>
);
