import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Icon } from 'semantic-ui-react';

function MemberList(props) {
  const { data, loading, error } = props;
  if (loading) return (<h1>Loading...</h1>);
  if (error) return `Error! ${error.message}`;
  return (
    <div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Detail</Table.HeaderCell>
            <Table.HeaderCell>Week</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Apartment</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>State</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { (data).map((booking) => (
            <Table.Row key={booking.id}>
              <Table.Cell>
                <Link to={`/booking/${booking.id}`}>
                  <Button icon>
                    <Icon name="chevron right" />
                  </Button>
                </Link>
              </Table.Cell>
              <Table.Cell>{ booking.week }</Table.Cell>
              <Table.Cell>{ booking.date }</Table.Cell>
              <Table.Cell>{ booking.apartment }</Table.Cell>
              <Table.Cell>{ booking.member.owner_names }</Table.Cell>
              <Table.Cell>{booking.state}</Table.Cell>
              <Table.Cell>{ booking.member.email }</Table.Cell>
              <Table.Cell>{ booking.member.owner_numbers }</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

export default MemberList;
