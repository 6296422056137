import React, { useContext } from 'react';
import { Table } from 'semantic-ui-react';
import './AllBookings.css';
import { UserDrawContext } from '../../context/UserDrawContext';
import drawTable from '../../helpers/queryProcessor';
import SingleBookingCell from './SingleBookingCell';

function AllBookings() {
//  const { data, loading, error } = useQuery(QueryListBookings);
  const { bookings } = useContext(UserDrawContext);
  if (bookings.loading) return (<h1>Loading...</h1>);
  if (bookings.error) return `Error! ${bookings.error.message}`;
  const weeks = drawTable(bookings.bookings);
  return (
    <Table striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Week</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Sandy Cape</Table.HeaderCell>
          <Table.HeaderCell>Waddy Point</Table.HeaderCell>
          <Table.HeaderCell>Wathumba</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        { weeks.map((week) => (
          <Table.Row key={week.week}>
            <Table.Cell disabled={week.isPast()}>{ week.week }</Table.Cell>
            <Table.Cell disabled={week.isPast()}>{ week.date }</Table.Cell>
            { week.isMaintenance() ? (
              <Table.Cell className="maintenance" disabled={week.isPast()} colSpan={3} textAlign="center">MAINTENANCE</Table.Cell>
            ) : (
              <>
                <SingleBookingCell booking={week.SANDY_CAPE} />
                <SingleBookingCell booking={week.WADDY_POINT} />
                <SingleBookingCell booking={week.WATHUMBA} />
              </>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default AllBookings;
