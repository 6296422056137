import React, { useContext } from 'react';
import { UserDrawContext } from '../../context/UserDrawContext';
import MemberList from './MemberList';
import './WeeksForSale.css';

function WeeksForSale() {
  const { bookings } = useContext(UserDrawContext);
  if (bookings.loading) return (<h1>Loading...</h1>);
  if (bookings.error) return `Error! ${bookings.error.message}`;

  // Obtain shares members have made avaialable and unfinancial member shares and combine them...
  const transferableBookings = bookings.bookings
    .filter((booking) => (booking.isAvailable()))
    .sort((a, b) => ((a.week - b.week) - ((b.apartment > a.apartment) ? 0.5 : -0.5)));

  return (
    <MemberList data={transferableBookings} loading={bookings.loading} error={bookings.error} />);
}

export default WeeksForSale;
