import React, { useContext } from 'react';
import { UserDrawContext } from '../../context/UserDrawContext';
import MemberList from './MemberList';
import WaddyTides from './Tides';
import './MyWeek.css';

// Add state for element selection for multiple weeks and ability to sell week
function MyWeek() {
  const { bookings } = useContext(UserDrawContext);
  if (bookings.loading) return (<h1>Loading...</h1>);
  if (bookings.error) return `Error! ${bookings.error.message}`;

  const myWeeks = bookings.bookings
    .filter((item) => item.isOwner())
    .sort((a, b) => ((a.week - b.week) - ((b.apartment > a.apartment) ? 0.5 : -0.5)));
  const firstWeekStartDate = myWeeks[0].date.replace(/\//g, '-');

  return (
    <div>
      <MemberList data={myWeeks} loading={bookings.loading} error={bookings.error} />
      <WaddyTides startDate={firstWeekStartDate} />
    </div>
  );
}

export default MyWeek;
