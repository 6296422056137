import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';

import Auth from '@aws-amplify/auth';
import Lambda from 'aws-sdk/clients/lambda';
import './Tides.css';
import config from '../../config';

function Tides(props) {
  const [tides, setTides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // invoke Lambda to retrieve HTML
    async function applyHtml() {
      const credentials = await Auth.currentCredentials();
      const lambda = new Lambda({ credentials: Auth.essentialCredentials(credentials), region: config.region });
      const params = {
        FunctionName: 'fraser-tides',
        Payload: JSON.stringify({ start_date: props.startDate }),
      };
      await lambda.invoke(params, (errLambda, data) => {
        if (errLambda) console.log(errLambda, errLambda.stack); // an error occurred
        const json = JSON.parse(data.Payload);
        setTides(json);
        setIsLoading(false);
      });
    }
    applyHtml();
  }, []);
  if (isLoading) return (<h2>Loading Tide Times...</h2>);

  return (
    <Table id="Tides-table" celled striped structured unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Day</Table.HeaderCell>
          <Table.HeaderCell>Tide</Table.HeaderCell>
          <Table.HeaderCell>Time</Table.HeaderCell>
          <Table.HeaderCell>Height</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        { tides.map((day) => (
          day.tides.map((detail, idx) => {
            const key = `${day.day}-${detail.time}`;
            return (
              <Table.Row key={key}>
                { idx === 0 && <Table.Cell rowSpan="4">{day.day}</Table.Cell> }
                <Table.Cell>{detail.type}</Table.Cell>
                <Table.Cell>{detail.time}</Table.Cell>
                <Table.Cell>{detail.height}</Table.Cell>
              </Table.Row>
            );
          })
        ))}
      </Table.Body>
    </Table>
  );
}

export default Tides;
