import { listAllUsers, listUsersInGroup } from '../actions/userActions';
import { parseCognitoUserAttributes } from '../helpers/userUtils';
// expects a map of all, financial and unfinancial Cognito User objects.
// Returns the model for the Context
const createUserModel = (async () => {
  const usersAndGroups = await Promise.all([
    listAllUsers(),
    listUsersInGroup('member'),
    listUsersInGroup('unfinancial'),
  ]);
  const [all, financial, unfinancial] = usersAndGroups;
  // users are either financial or unfinancial but not both so the code to create a lookup is simple
  const financialSet = new Set(financial.map((user) => user.Username));
  const unfinancialSet = new Set(unfinancial.map((user) => user.Username));

  const createUser = (attr) => ({
    ...attr,
    groups: [],
    addGroup(groupName) { this.groups.push(groupName); return this; },
    removeGroup(groupName) { this.groups = this.groups.filter((group) => group !== groupName); return this; },
    isFinancial() { return this.groups.includes('member'); },
    addShare(share) { this.share_numbers.push(share); return this; },
    removeShare(share) { this.share_numbers = this.share_numbers.filter((shareId) => share !== shareId); return this; },
    getSharesAsString() { return this.share_numbers.join(','); },
    canDelete() { return this.share_numbers.length === 0; },
  });
  const unsortedUsers = all.map((cognitoUser) => {
    const attr = parseCognitoUserAttributes(cognitoUser.Attributes);
    const user = createUser(attr);
    if (financialSet.has(attr.sub)) user.addGroup('member');
    if (unfinancialSet.has(attr.sub)) user.addGroup('unfinancial');
    return user;
  });
  const allUsers = unsortedUsers.sort((a, b) => {
    if (a.owner_names > b.owner_names) {
      return 1;
    }
    if (a.owner_names < b.owner_names) {
      return -1;
    }
    return 0;
  });
  return allUsers;
});
export default createUserModel;
