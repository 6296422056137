import React from 'react';
import { Image } from 'semantic-ui-react';

function NoMatch() {
  return (
    <div>
      <h1>Page Not Found</h1>
      <Image src="/404.jpg" />
    </div>
  );
}

export default NoMatch;
