//  Group array of items based on a field
function groupByField(arr, field) {
  const groupLikeIntoArray = (acc, curr) => {
    (acc[curr[field]] = acc[curr[field]] || []).push(curr);
    return acc;
  };

  const grouped = arr.reduce(groupLikeIntoArray, {});
  return grouped;
}

function sortObjArrayByField(array, field) {
  const fieldSort = (a, b) => a[field] - b[field];
  const mapToArrayByField = ([key, value]) => [key, value];
  const sorted = Object.entries(array).map(mapToArrayByField).sort(fieldSort);
  return sorted;
}

// break this into two methods
function createMapFromArrayByField(sorted, field, decorator) {
  const mapByField = (acc, curr) => ({ ...acc, ...{ [curr[field]]: curr } });

  const weeks = sorted.map((week) => {
    const [weekId, weekArray] = week; // array of (3) weeks
    const bookings = weekArray.reduce(mapByField, {}); // pack the (3) weeks into an object
    return decorator(bookings);
  });
  return weeks;
}

const decorateWeek = (weeks) => {
  const booking = weeks.SANDY_CAPE; // bit of a hack - just getting one week
  const {
    week, date, isoDate, period, state,
  } = booking;
  return ({
    week,
    period,
    date,
    isoDate,
    state,
    ...weeks,
    isPast() { return this.isoDate < Date.now(); },
    isMaintenance() { return booking.isMaintenance(); },
  });
};

function bookingsByWeekThenApartment(bookings) {
  const bookingsByWeek = groupByField(bookings, 'week'); // group bookings into 52 weeks of 3 bookings (by week)
  const sortedArrayOfWeeks = sortObjArrayByField(bookingsByWeek, 'week'); // order the grouped bookings by week desc
  const weeksByApartment = createMapFromArrayByField(sortedArrayOfWeeks, 'apartment', decorateWeek);
  return weeksByApartment;
}

export {
  bookingsByWeekThenApartment as default,
  groupByField,
  sortObjArrayByField,
  createMapFromArrayByField,
};
