import gql from 'graphql-tag';

export default gql`
query GetBookings {
    listBookings(limit: 200) {
     items {
        id
        date
        week
        state
        apartment
        period
        share_number
        occupant
        occupant_name
        occupant_email
        occupant_phone
     }
    }
  }
`;
