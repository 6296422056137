import React from 'react';
import { Button, Label } from 'semantic-ui-react';
import './TransferWeek.css';

function BookingAvailability(props) {
  const { booking, updateBooking, canEdit } = props;
  const isBooked = (booking.state === 'BOOKED');
  const alternateState = isBooked ? 'AVAILABLE' : 'BOOKED';

  return (
    <div>
      { canEdit ? (
        <Button
          content={isBooked ? 'Offer Week for Sale' : 'Withdraw Week from Sale'}
          icon={isBooked ? 'unlock' : 'lock'}
          onClick={() => updateBooking({ state: alternateState })}
        />
      ) : (
        <div><Label>State:</Label>{booking.state}</div>
      )}
    </div>
  );
}
export default BookingAvailability;
