// Create a separate client object so mutations can be decoupled from components.
// Am a little worried that hooks and their component coupling makes re-use more
//   difficult and this is the result.
// https://medium.com/@albertchu539/higher-order-components-in-a-react-hooks-world-69fe1f0b0791
// https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/450
import { Auth } from "aws-amplify"
import { createAppSyncLink } from "aws-appsync"
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import config from '../config';

const httpLink = createHttpLink({
  uri: config.appsync.endpoint,
});

const awsLink = createAppSyncLink({
  url: config.appsync.endpoint,
  region: config.region,
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    credentials: () => Auth.currentCredentials(),
    jwtToken: async () =>
      (await Auth.currentSession()).getAccessToken().getJwtToken(),
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
});

export default new ApolloClient({
  link: awsLink.concat(httpLink),
  cache: new InMemoryCache(),
});
