// AWS AppSyncClient is broken when it comes to Hooks.
// Had to adopt workaround documented here
// https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/450
// nc9 / ph0ph0 solution worked for me.

import { Auth } from 'aws-amplify';
import { createAppSyncLink, AUTH_TYPE } from 'aws-appsync';
import { ApolloClient } from 'apollo-boost';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import config from './config';

const httpLink = createHttpLink({
  uri: config.appsync.endpoint,
});

const awsLink = createAppSyncLink({
  url: config.appsync.endpoint,
  region: config.region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    credentials: () => Auth.currentCredentials(),
    jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
});

export default new ApolloClient({
  link: awsLink.concat(httpLink),
  cache: new InMemoryCache(),
});
