import React, { createContext, useEffect, useReducer } from 'react';
import userReducer from '../reducers/userReducer';
import bookingReducer from '../reducers/bookingReducer';
import createUserModel from './UserFactory';
import createBookingModel from './BookingFactory';

export const UserDrawContext = createContext();

export function UserDrawProvider(props) {
  const [users, userDispatch] = useReducer(userReducer, { users: [], loading: true });
  const [bookings, bookingDispatch] = useReducer(bookingReducer, { bookings: [], loading: true });

  // TODO: should be able to clean this up to iterate via groups rather than have imperative code
  useEffect(() => {
    (async () => {
      bookingDispatch({ type: 'PENDING' });
      userDispatch({ type: 'PENDING' });
      try {
        const values = await Promise.all([
          createUserModel(),
          createBookingModel(),
        ]);
        const [allUsers, rawBookings] = values;
        userDispatch({ type: 'LOADED', allUsers });

        // TODO: Consider moving this to its own provider, then consider using a composition to
        //   replace user and booking providers
        const userJoin = allUsers.reduce((acc, value) => {
          acc[value.sub] = value;
          return acc;
        }, {});

        const memberBookings = rawBookings.map((booking) => {
          const member = userJoin[booking.occupant];
          if (member) booking.setMember(member);
          return booking;
        });
        bookingDispatch({ type: 'LOADED', memberBookings });
      } catch (error) {
        userDispatch({ type: 'ERROR', error });
        bookingDispatch({ type: 'ERROR', error });
      }
    })();
  }, []);

  // get unfinancial members
  return (
    <UserDrawContext.Provider value={
        {
          users, userDispatch, bookings, bookingDispatch,
        }
        }
    >
      {props.children}
    </UserDrawContext.Provider>
  );
}
