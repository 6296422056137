import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Label, Icon, Button } from 'semantic-ui-react';
import './TransferWeek.css';

function TransferButton(props) {
  const { booking_id } = props;
  return (
    <Button as="div" labelPosition="left">
      <Label as="a" basic color="red" pointing="right">
        Transfer Week
      </Label>
      <Link to={`/booking/transfer/${booking_id}`}>
        <Button icon>
          <Icon name="exchange" />
        </Button>
      </Link>
    </Button>
  );
}
TransferButton.propTypes = {
  booking_id: PropTypes.string.isRequired,
};
export default TransferButton;
