import React, { useContext } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { UserDrawContext } from '../../context/UserDrawContext';
import useForm from '../../helpers/useForm';
import { createUser, addUserToGroup } from '../../actions/userActions';

function UserForm() {
  const { userDispatch } = useContext(UserDrawContext);
  // Define your state schema
  const stateSchema = {
    email: { value: '', error: '', blur: false },
    phone_number: { value: '', error: '', blur: false },
    share_numbers: { value: '', error: '', blur: false },
    owner_names: { value: '', error: '', blur: false },
    owner_numbers: { value: '', error: '', blur: false },
  };

  // Define your validationStateSchema
  // Note: validationStateSchema and stateSchema property
  // should be the same in-order validation works!
  const validationStateSchema = {
    email: {
      required: true,
      validator: {
        regEx: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        error: 'Invalid email format.',
      },
    },
    phone_number: {
      required: true,
      validator: {
        regEx: /^\+[1-9]\d{1,14}$/,
        error: 'Must be international formatted - eg +61734567890',
      },
    },
    share_numbers: {
      required: true,
      validator: {
        regEx: /^(\d{3})(,\s?\d{3})*$/,
        error: 'Must be comma separated list of 3 digit numbers.',
      },
    },
    owner_names: {
      required: true,
    },
    owner_numbers: {
      required: true,
    },
  };

  const handleSubmit = (async (state) => {
    // strip off the error value and push to cognito
    const user = Object.keys(state).reduce((acc, key) => {
      acc[key] = state[key].value;
      return acc;
    }, {});
    try {
      const newUser = await createUser(user);
      addUserToGroup(newUser.email, 'member');
      userDispatch({ type: 'ADD', newUser });
    } catch (error) {
      userDispatch({ type: 'ERROR', error });
    }
  });

  const {
    state, handleOnChange, handleOnBlur, handleOnSubmit, disable,
  } = useForm(
    stateSchema,
    validationStateSchema,
    handleSubmit,
  );
  return (
    <div>
      <Form onSubmit={handleOnSubmit}>
        <h4 className="ui dividing header">New User</h4>
        <Form.Group widths="equal">
          <Form.Input
            label="Display Names of Owner(s)"
            type="text"
            name="owner_names"
            placeholder="Display Name(s)"
            error={(state.owner_names.blur && state.owner_names.error.length !== 0) && state.owner_names.error}
            value={state.owner_names.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          <Form.Input
            label="Member Contact Phone Number(s)"
            type="text"
            name="owner_numbers"
            placeholder="Phone number(s)"
            error={(state.owner_numbers.blur && state.owner_numbers.error.length !== 0) && state.owner_numbers.error}
            value={state.owner_numbers.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label="Login / Contact Email"
            type="email"
            name="email"
            placeholder="Email"
            error={(state.email.blur && state.email.error.length !== 0) && state.email.error}
            value={state.email.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
          <Form.Input
            label="Mobile Phone for SMS messages"
            type="tel"
            name="phone_number"
            placeholder="Mobile Phone Number (eg +6144365552)"
            value={state.phone_number.value}
            error={(state.phone_number.blur && state.phone_number.error.length !== 0) && state.phone_number.error}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
          />
        </Form.Group>
        <Form.Input
          label="Share Numbers (comma separated)"
          type="text"
          name="share_numbers"
          placeholder="share numbers (comma)"
          value={state.share_numbers.value}
          error={(state.share_numbers.blur && state.share_numbers.error.length !== 0) && state.share_numbers.error}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
        <Button type="submit" disabled={disable}>
          Submit
        </Button>
      </Form>
    </div>
  );
}
export default UserForm;
