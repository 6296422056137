const attrProcessor = ((rawAttributes) => {
  let attr;
  // bit of a hack - for Cognito users, properties are returned as an array but
  //   for currentUser properties are returned as object - help it to work without
  //   mutating the original argument
  if (Array.isArray(rawAttributes)) {
    attr = [...rawAttributes];
  } else {
    attr = { ...rawAttributes };
  }

  // Cognito prepends all custom attributes with a "custom:" string in front of the
  //   property.  This function strips off that prefix returning a new array of attributes;

  const obj = {
    stripCustomPrefix() {
      const attributes = {};
      Object.entries(attr).forEach(([key, value]) => {
        if (key.startsWith('custom:')) {
          attributes[key.split(':')[1]] = value;
        } else {
          attributes[key] = value;
        }
      });
      attr = attributes;
      return this;
    },

    // converts share_number property from comma-separated string to array
    // and returns a new object.
    sharesToArray() {
      const sharesString = attr.share_numbers;
      const shares = sharesString ? sharesString.split(',') : [];
      attr = { ...attr, share_numbers: shares }; // just overwrite property in the copy
      return this;
    },

    // Cognito user queries returns user attributes in a {'Name':name, 'Value':value} format
    //   (note that Cognito does not apply this treatment to the 'currentUser' object)
    // Flatten this to a { name: value } format
    flattenAttributes() {
      const attributes = [];
      attr.forEach((elem) => {
        const { Name, Value } = elem;
        attributes[Name] = Value;
      });
      attr = attributes;
      return this;
    },

    getAttributes() {
      return attr;
    },

  };
  return obj;
});

const parseCurrentUserAttributes = (
  (attributes) => attrProcessor(attributes)
    .stripCustomPrefix()
    .sharesToArray()
    .getAttributes()
);


const parseCognitoUserAttributes = (
  (attributes) => attrProcessor(attributes)
    .flattenAttributes()
    .stripCustomPrefix()
    .sharesToArray()
    .getAttributes()
);

export { parseCurrentUserAttributes, parseCognitoUserAttributes };
