import React, { useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import {
  Divider, Form, Dropdown, Label, Grid, Segment, Header, Checkbox, Button,
} from 'semantic-ui-react';
import { AuthContext } from '../../context/AuthContext';
import { UserDrawContext } from '../../context/UserDrawContext';
import { updateUser } from '../../actions/userActions';
import { transferWeek } from '../../actions/bookingActions';
import './TransferShare.css';

function TransferShare() {
  const { users, userDispatch, bookings, bookingDispatch } = useContext(UserDrawContext);
  const [recipient, setRecipient] = useState();
  const [shares, setShares] = useState();
  const [validation, setValidation] = useState({ shares: true, recipient: true });
  const { isAdmin } = useContext(AuthContext);
  const { id } = useParams();
  const history = useHistory();

  if (users.loading) return (<h1>Loading...</h1>);
  if (users.error) return `Error! ${users.error.message}`;
  const allUsers = users.users;
  // Identify originating user for transfer from URL

  const originUser = allUsers.find((user) => user.sub === id);
  const initialShares = originUser.share_numbers.reduce((acc, share) => ({ ...acc, [share]: false }), {});
  if (!shares) setShares(initialShares); // can't inital shares state until user is loaded - if context is set up we'll have this


  // Create dropdown model - Only permit transfers to financial members
  const financialMembers = allUsers.filter((user) => user.groups.includes('member') && user.sub !== id);
  const transferRecipients = financialMembers.map((member) => ({
    key: member.sub,
    value: member.sub,
    text: `${member.owner_names} - ${member.email}`,
  }));


  const handleSubmit = (async (e) => {
    // TODO - get rid of this stuff with decent validation....
    e.preventDefault();

    const hasShares = Object.values(shares).reduce((acc, shareSelected) => acc || shareSelected, false);

    const recipientValidation = recipient !== undefined;

    if (!(recipientValidation && hasShares)) {
      setValidation({
        recipient: recipientValidation,
        shares: hasShares,
      });
      return;
    }


    // transfer shares - could make it transactional with a Lambda but I'm not going there for now....
    const recipientUser = allUsers.find((user) => user.sub === recipient);
    const transferShares = Object.keys(shares).reduce((acc, shareId) => (shares[shareId] ? acc.concat(shareId) : acc), []);

    transferShares.forEach((share) => {
      originUser.removeShare(share);
      recipientUser.addShare(share);
    });
    try {
      await updateUser(originUser.sub, { 'custom:share_numbers': originUser.getSharesAsString() });
      await updateUser(recipientUser.sub, { 'custom:share_numbers': recipientUser.getSharesAsString() });
      userDispatch({ type: 'EDIT', user: originUser });
      userDispatch({ type: 'EDIT', user: recipientUser });
      // update the user's bookings to reflect the transfer of shares
      
      const bookingsToUpdate = bookings.bookings.filter((booking) => transferShares.includes(booking.share_number));
      bookingsToUpdate.forEach(async (booking) => {
        const updatedBooking = await transferWeek(isAdmin, booking, recipientUser);
        bookingDispatch({ type: 'EDIT', updatedBooking });
      });


      history.push('/admin');
    } catch (error) {
      userDispatch(error);
    }
  });


  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Header as="h3">Permanent Share Ownership Transfer</Header>
        <Segment>
          <Grid columns={2} relaxed='very'>
            <Grid.Column>
              <Header as="h4">Shares to Transfer</Header>
                <Divider />
                <div className='TransferShare-origin'>
                  {originUser.family_name}
                </div>
              { originUser.share_numbers.map((share_number) => (
                <div key={share_number} className='TransferShare-origin'>
                  <Form.Checkbox                    
                    value={share_number}
                    label={share_number}
                    error={!validation.shares}
                    onFocus={() => setValidation({ ...validation, shares: true })}
                    onChange={(e,data) => setShares({ ...shares, [share_number]: data.checked })}
                  />
                </div>
              ))}
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">Recipient</Header>
              <Divider />
              <Dropdown
                placeholder="Select Share(s) Recipient"
                fluid
                search
                selection
                error={!validation.recipient}
                options={transferRecipients}
                onChange={(e, { value }) => setRecipient(value)}
                onFocus={() => setValidation({ ...validation, recipient: true })}
              />
            </Grid.Column>
          </Grid>
          <Divider vertical>And</Divider>
        </Segment>
        <Button type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}
export default TransferShare;
