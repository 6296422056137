import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import {
  Grid, Header, Divider, Segment, Label,
} from 'semantic-ui-react';
import { updateBooking } from '../../actions/bookingActions';
import BookingAvailability from './BookingAvailability';
import TransferButton from './TransferButton';
import ApartmentDetails from './ApartmentDetails';
import OccupantDetails from './OccupantDetails';
import OccupantForm from './OccupantForm';
import { UserDrawContext } from '../../context/UserDrawContext';
import { AuthContext } from '../../context/AuthContext';
import './TransferWeek.css';

function BookingDetails() {
  const { bookings, bookingDispatch } = useContext(UserDrawContext);
  const { isAdmin } = useContext(AuthContext);
  const { id } = useParams();
  const [isEditingContacts, setIsEditingContacts] = useState(false);
  if (bookings.loading) return (<h1>Loading...</h1>);
  if (bookings.error) return `Error! ${bookings.error.message}`;

  // Identify originating share for transfer
  const allBookings = bookings.bookings;
  const sourceBooking = allBookings.find((booking) => booking.id === id);
  const canEdit = !sourceBooking.isPast() && (isAdmin || sourceBooking.isOwner());

  const modifyBooking = (async (newAttrs) => {
    try {
      const updatedBooking = { ...sourceBooking, ...newAttrs };
      await updateBooking(isAdmin, updatedBooking);
      bookingDispatch({ type: 'EDIT', updatedBooking });
    } catch (err) {
      console.log(err);
      bookingDispatch({ type: 'ERROR', err });
    }
  });

  return (
    <div>
      <Header as="h3">Booking Details</Header>
      <Header as="h4">Apartment</Header>
      <Segment>
        <ApartmentDetails booking={sourceBooking} />
      </Segment>
      <Header as="h4">Owner</Header>
      <Segment>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <Label>Name:</Label>
              {sourceBooking.member.owner_names}
            </Grid.Column>
            <Grid.Column>
              <BookingAvailability
                booking={sourceBooking}
                updateBooking={modifyBooking}
                canEdit={canEdit}
              />
            </Grid.Column>
            <Grid.Column>
              {canEdit && (<TransferButton booking_id={id} />)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Header as="h4">Occupant</Header>
      <Segment>
        {!isEditingContacts ? (
          <OccupantDetails
            booking={sourceBooking}
            canEdit={canEdit}
            startEdit={setIsEditingContacts}
          />
        ) : (
          <OccupantForm
            booking={sourceBooking}
            isEditing={setIsEditingContacts}
            updateBooking={modifyBooking}
          />
        )}
      </Segment>
    </div>
  );
}
export default BookingDetails;
