import React, { useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router';
import {
  Form, Label, Dropdown, Grid, Segment, Header, Divider, Button,
} from 'semantic-ui-react';
import { UserDrawContext } from '../../context/UserDrawContext';
import { AuthContext } from '../../context/AuthContext';
import { transferWeek } from '../../actions/bookingActions';
import './TransferWeek.css';

function TransferWeek() {
  const { users, bookings, bookingDispatch } = useContext(UserDrawContext);
  const { isAdmin } = useContext(AuthContext);
  const [recipient, setRecipient] = useState();
  const [checkbox, setCheckbox] = useState(false);
  const [validation, setValidation] = useState({ recipient: true, checkbox: true });
  const { id } = useParams();
  const history = useHistory();

  if (bookings.loading) return (<h1>Loading...</h1>);
  if (bookings.error) return `Error! ${bookings.error.message}`;
  // Identify originating share for transfer
  const allBookings = bookings.bookings;
  const sourceBooking = allBookings.find((booking) => booking.id === id);
  // Only permit transfers to financial members
  const financialMembers = users.users.filter((user) => user.groups.includes('member') && sourceBooking.occupant !== id);
  const transferRecipients = financialMembers.map((member) => ({
    key: member.sub,
    value: member.sub,
    text: `${member.owner_names} - ${member.email}`,
  }));

  const handleSubmit = (async (e) => {
    e.preventDefault();
    const recipientValidation = recipient !== undefined;
    const checkboxValidation = isAdmin || checkbox;

    if (!(recipientValidation && checkboxValidation)) {
      setValidation({
        recipient: recipientValidation,
        checkbox: checkboxValidation,
      });
      return;
    }

    // transfer week
    const newOccupant = users.users.find((user) => user.sub === recipient);
    try {
      const updatedBooking = await transferWeek(isAdmin, sourceBooking, newOccupant);
      bookingDispatch({ type: 'EDIT', updatedBooking });
    } catch (err) {
      console.log(err);
      bookingDispatch({ type: 'ERROR', err });
    }
    history.push('/my');
  });


  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Header as="h3">Accommodation Week Transfer</Header>
        <Segment>
          <Grid columns={2} relaxed="very">
            <Grid.Column>
              <Header as="h4">Current Occupant</Header>
              <Divider />
              <Grid columns="equal">
                <Grid.Row>
                  <Grid.Column className="label"><Label>From:</Label></Grid.Column>
                  <Grid.Column className="value">{sourceBooking.member.owner_names}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className="label"><Label>Week:</Label></Grid.Column>
                  <Grid.Column className="value">{sourceBooking.week}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className="label"><Label>Date:</Label></Grid.Column>
                  <Grid.Column className="value">{sourceBooking.date}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column className="label"><Label>Apartment:</Label></Grid.Column>
                  <Grid.Column className="value">{sourceBooking.apartment}</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Header as="h4">New Occupant</Header>
              <Divider />
              <Dropdown
                placeholder="Select Share(s) Recipient"
                fluid
                search
                selection
                error={!validation.recipient}
                options={transferRecipients}
                onChange={(e, { value }) => setRecipient(value)}
                onFocus={() => setValidation({ ...validation, recipient: true })}
              />
            </Grid.Column>
          </Grid>
          <Divider vertical>And</Divider>
        </Segment>
        { !isAdmin && (
        <div className="TransferWeek-acknowledgement">
          <Form.Checkbox
            onChange={(e, { checked }) => setCheckbox(checked)}
            onFocus={() => setValidation({ ...validation, checkbox: true })}
            error={!validation.checkbox}
            label="By submitting this form I acknowledge I will no longer have control of this week"
          />
        </div>
        )}
        <Button type="submit">Submit</Button>
      </Form>
    </div>
  );
}
export default TransferWeek;
