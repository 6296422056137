import gql from 'graphql-tag';

// have to update this to have a custom resolver to restrict updating state and owner of share.
const updateBookingDocument = (isAdmin) => {
  const document = `
    mutation UpdateBookingState($id: ID!, $state: BookingState, $occupant: ID, $occupant_name: String, $occupant_phone: String, $occupant_email: String) {
      ${isAdmin ? 'updateBooking' : 'updateBookingMember'} (  
        input: {
          id: $id
          state: $state
          occupant: $occupant
          occupant_name: $occupant_name
          occupant_phone: $occupant_phone
          occupant_email: $occupant_email
        }
      ) {   id
            date
            week
            state
            apartment
            period
            share_number
            occupant
            occupant_name
            occupant_phone
            occupant_email
      }
    }`;
  return gql(document);
};

export default updateBookingDocument;
