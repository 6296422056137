// https://serverless-stack.com/chapters/environments-in-create-react-app.html
const dev = {
  cognito: {
    user_pool_id: 'ap-southeast-2_N1ST9xQTh',
    client_id: 'h93n641sle172ms1iq03bjtv6',
    identity_pool_id: 'ap-southeast-2:791058bf-c0f9-4316-a71c-9f6ba911ec1b',
  },
  appsync: {
    endpoint: 'https://jpb3png32jhqfd42vhbbrxnoym.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  },
  region: 'ap-southeast-2',
  segment: 'VsqTTIHKZDndEHzZbL0t2bVpTkfflrBH',
};

const prod = {
  cognito: {
    user_pool_id: 'ap-southeast-2_N1ST9xQTh',
    client_id: 'h93n641sle172ms1iq03bjtv6',
    identity_pool_id: 'ap-southeast-2:791058bf-c0f9-4316-a71c-9f6ba911ec1b',
  },
  appsync: {
    endpoint: 'https://jpb3png32jhqfd42vhbbrxnoym.appsync-api.ap-southeast-2.amazonaws.com/graphql',
  },
  region: 'ap-southeast-2',
  segment: 'fcU0G4pgFLNWIPdjkzPGh4QtUcF0fdfJ',
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  ...config,
};
