import React, { useContext } from 'react';
import { Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './SingleBookingCell.css';

function SingleBookingCell(props) {
  const { booking } = props;
  const { currentUser } = useContext(AuthContext);
  let backgroundColor = 'inherit';
  if (booking.isMaintenance()) {
    backgroundColor = 'lightblue';
  } else if (!booking.member.isFinancial()) {
    backgroundColor = '#FF3333';
  } else if (booking.isAvailable()) {
    backgroundColor = '#66FF99';
  } else if (booking.isOwner()) {
    backgroundColor = '#CCCCCC';
  }
  let color = 'inherit';
  if (booking.isPast()) {
    color = 'rgba(40,40,40,.3)';
  }
  const occupant = booking.isMaintenance() ? 'MAINTENANCE' : booking.member.owner_names;

  //const occupantHtml = booking.isOwner() ? (<Link to="/my">{occupant}</Link>) : (<div>{ occupant }</div>);

  return (
    <Table.Cell className="SingleBookingCell" disabled={booking.isMaintenance()} style={{ backgroundColor, color }}>
      <Link to={`/booking/${booking.id}`}>{ occupant }</Link>
    </Table.Cell>
  );
}

export default SingleBookingCell;
