const reducer = (state, action) => {
  switch (action.type) {
    case 'PENDING':
      return { users: [], loading: true };
    case 'LOADED':
      return { users: action.allUsers, loading: false };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: state.error,
      };
    case 'ADD':
      // persist to cognito and add result to array
      return {
        ...state,
        users: [...state.users, action.newUser],
      };
    case 'REMOVE':
      // remove from cognito and add result to array
      return {
        ...state,
        users: state.users.filter((user) => user.email !== action.email),
      };
    case 'EDIT': {
      // replace the element with the one in the state
      const newUsers = state.users.map((item) => {
        if (item.sub === action.user.sub) {
          return action.user;
        } else {
          return item;
        }
      });
      return {
        ...state,
        users: newUsers
      };
    }
    default:
      console.log('hit default');
      return {};
  }
};
//{type: "ADD", {user: {attributes}}};
//{type: "REMOVE", {id: userId}};
//{type: "FINANCIAL", {id: userId}};
//{type: "UNFINANCIAL", {id: userId}}; // not admin
//{type: "TRANSFER_SHARE", {id: shareID, recipient: {userId}};

export default reducer;
