import Auth from '@aws-amplify/auth';
import client from '../AwsApolloClient';
import { QueryListBookings } from '../GraphQL';
import { parseCurrentUserAttributes } from '../helpers/userUtils';

const createBookingModel = (async () => {
  const values = await Promise.all([
    Auth.currentAuthenticatedUser(),
    client.query({ query: QueryListBookings }),
  ]);
  const [currentUser, bookingsResult] = values;

  const currentUserAttr = parseCurrentUserAttributes(currentUser.attributes);

  const appsyncBookings = bookingsResult.data.listBookings.items;

  const createBooking = (attr, isoDate) => ({
    ...attr,
    isoDate,
    setMember(member) { this.member = member; this.occupant = member.sub; return this; },
    setState(state) { this.state = state; return this; },
    isOwner() { return this.member && this.member.sub === currentUserAttr.sub; },
    isPast() { return this.isoDate < Date.now(); },
    isMaintenance() { return this.state === 'MAINTENANCE'; },
    isAvailable() { return this.member && (this.state === 'AVAILABLE' || !this.member.isFinancial()) && !this.isPast(); },
  });

  const bookings = appsyncBookings.map((appsyncBooking) => {
    const isoDate = Date.parse(appsyncBooking.date.split('/').reverse().join('-'));
    const booking = createBooking(appsyncBooking, isoDate);
    return booking;
  });

  return bookings;
});

export default createBookingModel;
