import Auth from '@aws-amplify/auth';
import { parseCognitoUserAttributes } from '../helpers/userUtils';
import awsCreds from '../config';
import { resultKeyNameFromField } from 'apollo-utilities';
//https://github.com/SophieDeBenedetto/catbook-redux/blob/blog-post/src/components/cats/CatPage.js

const AWS = require('aws-sdk');

const customAttributes = [
  'owner_numbers',
  'owner_names',
  'share_numbers',
];

const annotateCognito = ((attr) => {
  const attrCognito = Object.keys(attr).reduce((acc, key) => {
    const value = attr[key];
    if (customAttributes.includes(key)) {
      acc[`custom:${key}`] = value;
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
  return attrCognito;
});

async function createUser(attributes) {
  const { email } = attributes;
  const cognitoAttr = annotateCognito(attributes);

  // Obtain the user attributes and generated the forms required by the API
  const userAttributesArray = Object.keys(cognitoAttr).reduce((acc, key) => {
    acc.push({ Name: key, Value: cognitoAttr[key] });
    return acc;
  }, []);
  const credentials = await Auth.currentCredentials();

  // Begin the user creation and validation operations...
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({ 
    apiVersion: '2016-04-18',
    credentials: Auth.essentialCredentials(credentials),
    region: awsCreds.region,
  });

  const adminCreateUserParams = {
    UserPoolId: awsCreds.cognito.user_pool_id,
    Username: email,
    DesiredDeliveryMediums: ['EMAIL'],
    UserAttributes: userAttributesArray,
  };
  // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/CognitoIdentityServiceProvider.html#adminCreateUser-property
  const cognitoUser = await cognitoidentityserviceprovider.adminCreateUser(adminCreateUserParams).promise();
  const user = parseCognitoUserAttributes(cognitoUser.User.Attributes);
  return user;
}

async function updateUser(email, attributes) {
  const cognitoAttr = annotateCognito(attributes);
  const credentials = await Auth.currentCredentials();
  const userAttributesArray = Object.keys(cognitoAttr).reduce((acc, key) => {
    acc.push({ Name: key, Value: cognitoAttr[key] });
    return acc;
  }, []);
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18',
    credentials: Auth.essentialCredentials(credentials),
    region: awsCreds.region,
  });
  const adminUpdateUserParams = {
    UserPoolId: awsCreds.cognito.user_pool_id,
    Username: email,
    UserAttributes: userAttributesArray,
  };
  await cognitoidentityserviceprovider.adminUpdateUserAttributes(adminUpdateUserParams).promise();
}

async function deleteUser(email) {
  const credentials = await Auth.currentCredentials();
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({ 
    apiVersion: '2016-04-18',
    region: awsCreds.region,
    credentials: Auth.essentialCredentials(credentials),
  });
  const adminDeleteUserParams = {
    UserPoolId: awsCreds.cognito.user_pool_id,
    Username: email,
  };
  const deletedUser = await cognitoidentityserviceprovider.adminDeleteUser(adminDeleteUserParams).promise();
  return deletedUser;
}

async function addUserToGroup(userName, groupName) {
  // Begin the user creation and validation operations...
  const credentials = await Auth.currentCredentials();
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18',
    region: awsCreds.region,
    credentials: Auth.essentialCredentials(credentials),
  });
  const params = {
    GroupName: groupName, /* required */
    UserPoolId: awsCreds.cognito.user_pool_id, /* required */
    Username: userName, /* required */
  };
  await cognitoidentityserviceprovider.adminAddUserToGroup(params).promise();
}

async function removeUserFromGroup(userName, groupName) {
  // Begin the user creation and validation operations...
  const credentials = await Auth.currentCredentials();
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18',
    region: awsCreds.region,
    credentials: Auth.essentialCredentials(credentials),
  });
  const params = {
    GroupName: groupName, /* required */
    UserPoolId: awsCreds.cognito.user_pool_id, /* required */
    Username: userName, /* required */
  };
  await cognitoidentityserviceprovider.adminRemoveUserFromGroup(params).promise();
}

async function listAllUsers() {
  const credentials = await Auth.currentCredentials();
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18',
    region: awsCreds.region,
    credentials: Auth.essentialCredentials(credentials),
  });
  const params = {
    UserPoolId: awsCreds.cognito.user_pool_id,
    Limit: 50,
  };
  let users = [];
  const retrievePageUsers = (async (reqParams) => {
    const result = await cognitoidentityserviceprovider.listUsers(reqParams).promise();
    users = [...users, ...result.Users];
    if (result.PaginationToken) await retrievePageUsers({ ...reqParams, PaginationToken: result.PaginationToken });
  });
  await retrievePageUsers(params);
  return users;
}

async function listUsersInGroup(groupName) {
  const credentials = await Auth.currentCredentials();
  const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18',
    region: awsCreds.region,
    credentials: Auth.essentialCredentials(credentials),
  });
  const params = {
    UserPoolId: awsCreds.cognito.user_pool_id,
    GroupName: groupName,
    Limit: 50,
  };
  let users = [];
  const retrievePageUsers = (async (reqParams) => {
    const result = await cognitoidentityserviceprovider.listUsersInGroup(reqParams).promise();
    users = [...users, ...result.Users];
    if (result.NextToken) {
      await retrievePageUsers({ ...reqParams, NextToken: result.NextToken });
    }
  });
  await retrievePageUsers(params);
  return users;
}

export {
  createUser,
  updateUser,
  deleteUser,
  listAllUsers,
  listUsersInGroup,
  addUserToGroup,
  removeUserFromGroup,
};
