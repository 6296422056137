import React, { createContext, useState, useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import { parseCurrentUserAttributes } from '../helpers/userUtils';

export const AuthContext = createContext();

export function AuthProvider(props) {
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const currentCognitoUser = await Auth.currentAuthenticatedUser();
        const groups = currentCognitoUser.signInUserSession.accessToken.payload['cognito:groups'];
        // TODO: Remove the following comment - just granting non-admin users admin to expose
        //   Admin UI to test that IAM functionality prevents operations.
        if (groups.includes('admin')) setIsAdmin(true);
        //  setIsAdmin(true);
        const currentUserAttr = parseCurrentUserAttributes(currentCognitoUser.attributes);
        setCurrentUser({ ...currentUserAttr, groups });
        setLoading(false);
      } catch (err) {
        setError(err);
        console.log(err);
      }
    })();
  }, []);

  // get unfinancial members
  return (
    <AuthContext.Provider value={{
      currentUser, isAdmin, loading, error,
    }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
