import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Menu } from 'semantic-ui-react';
import { AuthContext } from '../context/AuthContext';


function ReactFragment() {
  const { isAdmin } = useContext(AuthContext);
  const { pathname } = useLocation();
  return (
    <>
      <Menu.Item active={pathname === '/'}><Link to="/">Draw</Link></Menu.Item>
      <Menu.Item active={pathname === '/forsale'}><Link to="/forsale">For Sale</Link></Menu.Item>
      <Menu.Item active={pathname === '/my'}><Link to="/my">My Week</Link></Menu.Item>
      { isAdmin
          && (
            <Menu.Item active={pathname === '/admin'}><Link to="/admin">Admin</Link></Menu.Item>
          ) }
    </>
  );
}

export default ReactFragment;
