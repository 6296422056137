import ApolloClient from '../helpers/ApolloClient';
import updateBookingDocument from '../GraphQL/UpdateBooking';

async function updateBooking(isAdmin, booking) {
  const graphqlVariables = ((
    {
      id, state, occupant, occupant_name, occupant_email, occupant_phone,
    },
  ) => (
    {
      id, state, occupant, occupant_name, occupant_email, occupant_phone,
    }
  ))(booking);
    // TODO: should create a return object based on the graphQL result
  const mutation = updateBookingDocument(isAdmin);
  const mutationResult = await ApolloClient.mutate({ mutation, variables: graphqlVariables });
  return booking;
}

async function transferWeek(isAdmin, booking, newOccupant) {
  const updatedBooking = {
    ...booking,
    occupant: newOccupant.sub,
    state: 'BOOKED',
    occupant_name: newOccupant.owner_names,
    occupant_phone: newOccupant.owner_numbers,
    occupant_email: newOccupant.email,
    member: newOccupant,
  };
  await updateBooking(isAdmin, updatedBooking);
  return updatedBooking;
}

export {
  transferWeek,
  updateBooking,
};
