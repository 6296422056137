import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import './Navigation.css';
import 'semantic-ui-css/semantic.min.css';

// AppSync and Apollo libraries
import { AuthContext } from '../context/AuthContext';
import { UserDrawProvider } from '../context/UserDrawContext';

// Components
import AllBookings from './draw/AllBookings';
import BookingDetails from './draw/BookingDetails';
import MyWeek from './draw/MyWeek';
import WeeksForSale from './draw/WeeksForSale';
import TransferWeek from './draw/TransferWeek';
import UserList from './admin/UserList';
import UserForm from './admin/UserForm';
import TransferShare from './admin/TransferShare';
import NoMatch from './NoMatch';


function Navigation() {
  // want to capture unique pathnames for analytics - router spews out alot of
  //   the same - possibly due to undesirable re-render issues on my part.
  // So use a global state hack - it will miss duplicate clicks but that
  // shouldn't be a real problem relative to the upside.
  const history = useHistory();
  history.listen((location) => {
    if (window.pathname_for_analytics !== location.pathname) {
      window.analytics.page(location.pathname);
      window.pathname_for_analytics = location.pathname;
    }
  });
  const { isAdmin } = useContext(AuthContext);
  return (
    <div className="App-content">
      <UserDrawProvider>
        <Switch>
          <Route exact path="/" component={AllBookings} />
          <Route exact path="/forsale" component={WeeksForSale} />
          <Route exact path="/my" component={MyWeek} />
          <Route path="/booking/transfer/:id" component={TransferWeek} />
          <Route path="/booking/:id" component={BookingDetails} />
          { !isAdmin && <Route path="/admin" component={NoMatch} />}
          <Route exact path="/admin" component={UserList} />
          <Route exact path="/admin/new" component={UserForm} />
          <Route path="/admin/transfer/:id" component={TransferShare} />
          <Route component={NoMatch} />
        </Switch>
      </UserDrawProvider>
    </div>
  );
}
export default Navigation;
