import React from 'react';
import { Link } from 'react-router-dom';
import {
  Label, Icon, Grid, Button, Container,
} from 'semantic-ui-react';
import './OccupantDetails.css';

function OccupantDetails(props) {
  const { booking, canEdit, startEdit } = props;

  return (
    <div>
      <div className="OccupantDetails-editContainer">
        { canEdit
          && (
            <Button
              icon="edit"
              floated="right"
              onClick={() => startEdit(true)}
            />
          )}
      </div>
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column>
            <Label>Name:</Label>
            {booking.occupant_name}
          </Grid.Column>
          <Grid.Column>
            <Label>Phone:</Label>
            {booking.occupant_phone}
          </Grid.Column>
          <Grid.Column>
            <Label>Email:</Label>
            {booking.occupant_email}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
export default OccupantDetails;
