import React from 'react';
import PropTypes from 'prop-types';
import {
  Label, Grid, Header, Divider,
} from 'semantic-ui-react';

function ApartmentDetails(props) {
  const { booking } = props;
  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <Label>Week:</Label>
          {booking.week}
        </Grid.Column>
        <Grid.Column>
          <Label>Date:</Label>
          {booking.date}
        </Grid.Column>
        <Grid.Column>
          <Label>Apartment:</Label>
          {booking.apartment}
        </Grid.Column>
        <Grid.Column>
          <Label>Share:</Label>
          {booking.share_number}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

ApartmentDetails.propTypes = {
  booking: PropTypes.shape({
    week: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    apartment: PropTypes.string.isRequired,
    share_number: PropTypes.string.isRequired,
  }).isRequired,
};

export default ApartmentDetails;
