import { useState, useEffect, useCallback } from 'react';

// from https://hackernoon.com/react-form-validation-using-react-hooks-5859c32280ca
//   Added a blurhandler so I only start nagging users after they've exited the field
//   the first time.  Functionality enabled/disabled by setting blur to true/false
//   in the component's initial state.
function useForm(stateSchema, validationSchema = {}, callback) {
  const [state, setState] = useState(stateSchema);
  const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  // Disable button in initial render.
  useEffect(() => {
    setDisable(true);
  }, []);

  // Used to disable submit button if there's an error in state
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component
  const validateState = useCallback(() => {
    const hasErrorInState = Object.keys(validationSchema).some((key) => {
      const isInputFieldRequired = validationSchema[key].required;
      const stateValue = state[key].value; // state value
      const stateError = state[key].error; // state error
      return (isInputFieldRequired && !stateValue) || stateError;
    });

    return hasErrorInState;
  }, [state, validationSchema]);

  // For every changed in our state this will be fired
  // To be able to disable the button
  useEffect(() => {
    if (isDirty) {
      setDisable(validateState());
    }
  }, [state, isDirty]);
  // Used to handle every changes in every input
  const handleOnChange = useCallback(
    (event) => {
      setIsDirty(true);

      const { name, value } = event.target;

      let error = '';
      if (validationSchema[name].required) {
        if (!value) {
          error = 'This is required field.';
        }
      }

      if (
        validationSchema[name].validator !== null &&
        typeof validationSchema[name].validator === 'object'
      ) {
        if (value && !validationSchema[name].validator.regEx.test(value)) {
          error = validationSchema[name].validator.error;
        }
      }

      setState((prevState) => ({
        ...prevState,
        [name]: { ...prevState[name], value, error },
      }));
    },
    [validationSchema],
  );

  const handleOnBlur = useCallback(
    (event) => {
      const { name } = event.target;
      setState((prevState) => ({
        ...prevState,
        [name]: { ...prevState[name], blur: true },
      }));
    },
  );
  const handleOnSubmit = useCallback(
    (event) => {
      event.preventDefault();

      // Make sure that validateState returns false
      // Before calling the submit callback function
      if (!validateState()) {
        callback(state);
      }
    },
    [state],
  );

  return { state, disable, handleOnChange, handleOnBlur, handleOnSubmit };
}

export default useForm;
