import React, { useContext } from 'react';
import {
  Table, Button, Icon, Label, Grid, Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { deleteUser, removeUserFromGroup, addUserToGroup, updateUser } from '../../actions/userActions';
import { transferWeek } from '../../actions/bookingActions';
import { UserDrawContext } from '../../context/UserDrawContext';

function UserList() {
  const { users, userDispatch, bookings, bookingDispatch } = useContext(UserDrawContext);

  const removeUser = (async (email) => {
    try {
      await deleteUser(email);
      userDispatch({ type: 'REMOVE', email });
    } catch (error) {
      userDispatch({ type: 'ERROR', error });
    }
  });

  const toggleMembership = (async (user) => {
    try {
      // TODO - clean this up.
      const TOGGLE_GROUPS = ['member', 'unfinancial'];
      const { groups } = user;
      const groupToRemove = TOGGLE_GROUPS.find((group) => groups.includes(group));
      const groupToAdd = TOGGLE_GROUPS.find((group) => !groups.includes(group));
      await addUserToGroup(user.email, groupToAdd);
      await removeUserFromGroup(user.email, groupToRemove);
      const updatedUser = { ...user, groups: [groupToAdd] };
      userDispatch({ type: 'EDIT', user: updatedUser });
      // even rougher - find bookings for user and update bookings to be non-financial
      const bookingsToUpdate = bookings.bookings.filter((booking) => booking.occupant === user.sub);
      bookingsToUpdate.forEach((updatedBooking) => {
        updatedBooking.setMember(updatedUser);
        bookingDispatch({ type: 'EDIT', updatedBooking });
      });
    } catch (error) {
      console.log(error);
      userDispatch({ type: 'ERROR', error });
    }
  });

  if (users.loading) return (<h1>Loading...</h1>);
  if (users.error) return `Error! ${users.error.message}`;
  return (
    <div>
      <Segment basic clearing>
        <Link to="/admin/new">                      
          <Button floated="right" icon>
            <Icon name="add" />
          </Button>
        </Link>
      </Segment>
      <Table striped celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Shares</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          { (users.users).map((user) => (
            <Table.Row key={user.sub}>
              <Table.Cell>
                { user.owner_names }
              </Table.Cell>
              <Table.Cell>{ user.email }</Table.Cell>
              <Table.Cell>{ user.phone_number }</Table.Cell>
              <Table.Cell textAlign="right">
                { user.share_numbers.length !== 0
                && (
                  <Button as="div" labelPosition="left">
                    <Label as="a" basic>
                      {(user.share_numbers.join(', '))}
                    </Label>
                    <Link to={`/admin/transfer/${user.sub}`}>
                      <Button icon>
                        <Icon name="exchange" />
                      </Button>
                    </Link>
                  </Button>
                )}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Button as="div" labelPosition="left">
                  {user.isFinancial() ? (
                    <Label as="a" basic color="green" pointing="right">
                      Finacial
                    </Label>
                  ) : (
                    <Label as="a" basic color="red" pointing="right">
                      Unfinacial
                    </Label>
                  )}
                  <Button icon onClick={() => toggleMembership(user)}>
                    <Icon name="exchange" />
                  </Button>
                </Button>
              </Table.Cell>
              <Table.Cell>
                {user.share_numbers.length === 0
                  && (
                  <Button icon onClick={() => removeUser(user.email)}>
                    <Icon color="red" name="delete" />
                  </Button>
                  )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

export default UserList;
