
import React, { useState } from 'react';
import {
  Button,
  Container,
  Menu,
  Responsive,
  Segment,
  Visibility,
} from 'semantic-ui-react';
import { Auth } from 'aws-amplify';

import MenuFragment from './MenuFragment';

function DesktopContainer(props) {
  const [fixed, setFixed] = useState(true);
  const hideFixedMenu = () => setFixed(false);
  const showFixedMenu = () => setFixed(true);
  const { children } = props;
  const getWidth = () => {
    const isSSR = typeof window === 'undefined';
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
  };

  return (
    <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
      <Visibility
        once={false}
        onBottomPassed={showFixedMenu}
        onBottomPassedReverse={hideFixedMenu}
      >
        <Segment
          inverted
          textAlign="center"
          style={{ minHeight: 50, padding: '1em 0em' }}
          vertical
        >
          <Menu
            fixed={fixed ? 'top' : null}
            inverted
            pointing={!fixed}
            secondary={!fixed}
            size="large"
          >
            <Container>
              <MenuFragment />
              <Menu.Item position="right">
                <Button as="a" onClick={() => Auth.signOut()} inverted primary={fixed} style={{ marginLeft: '0.5em' }}>
                Log out
                </Button>
              </Menu.Item>
            </Container>
          </Menu>
        </Segment>
      </Visibility>
      {children}
    </Responsive>
  );
}

export default DesktopContainer;
